<template>
    <div>
        <carousel :perPage="1" :autoplay="true" :loop="true" :adjustableHeight="true" :paginationColor="bgColorTheme()"
            paginationPosition="bottom-overlay" class="mt-3">
            <slide v-for="(item, index) in items" :key="'banner' + index">
                <div @click="bannerToggle(item)" :data-toggle="modalToggle" data-target="#bannerModal" class="banner"
                    :style="{
                        'background-image': `url('${item.imagePath}'), url('${noImg}')`,
                        width: '100%',
                        'background-size': 'cover',
                        'background-position': 'center center',
                    }"></div>
            </slide>
        </carousel>
        <div class="modal fade" id="bannerModal" style="z-index: 9999; " data-backdrop="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="close" data-dismiss="modal">
                            <div class="d-flex justify-content-end "> <img width="10%" src="/img/close.png">
                            </div>
                        </div>
                        <detail v-bind:cartTotal="cartTotal" v-bind:SKUObjectId="SKUObjectId"
                            @update-cartTotal="updateCartTotal">
                        </detail>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import poscrm from '@/services/poscrm'
import Detail from './AppProductDetails'
import { colorNameToHex } from 'vue-phone-number-input/dist/vue-phone-number-input.common'
export default {
    components: {
        Detail,
    },
    data() {
        return {
            showDropdown: false,
            items: [],
            cartTotal: 0,
            SKUObjectId: '',
            modalToggle: '',
        }
    },
    created() {
        this.getOrderShopSetting()
    },
    computed: {
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },

        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    methods: {
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        updateCartTotal(total) {
            this.cartTotal = total
            this.$emit('update-cartTotal', total)
            this.$forceUpdate()
        },
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    let orderShopSettingCollection = {}
                    orderShopSettingCollection = res.data?.data?.[0] || null
                    this.items = orderShopSettingCollection?.banner || null

                })
            } catch (error) {
                throw new Error(error)
            }
        },
        bannerToggle(item) {
            this.modalToggle = ''
            if (item.action == 'Product') {
                this.modalToggle = 'modal'
                this.SKUObjectId = item.data
                this.trackButtonClick('menulist/banner' + item.action)
            }
            else if (item.action == 'URL') {
                this.trackButtonClick('menulist/banner' + item.action)
                this.modalToggle = ''
                if (item.data.startsWith('https')) {
                    window.open(item.data.trim());
                } else {
                    window.open('https://' + item.data.trim());
                }
            }
        },
        bgColorTheme() {
            return $(':root').css('--bg-color-theme') || "#ffffff"
        },
    },
}
</script>
<style>
.banner {
    border-radius: 10px;
    aspect-ratio: 2 / 1;
}
</style>
