var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('carousel',{staticClass:"mt-3",attrs:{"perPage":1,"autoplay":true,"loop":true,"adjustableHeight":true,"paginationColor":_vm.bgColorTheme(),"paginationPosition":"bottom-overlay"}},_vm._l((_vm.items),function(item,index){return _c('slide',{key:'banner' + index},[_c('div',{staticClass:"banner",style:({
                    'background-image': `url('${item.imagePath}'), url('${_vm.noImg}')`,
                    width: '100%',
                    'background-size': 'cover',
                    'background-position': 'center center',
                }),attrs:{"data-toggle":_vm.modalToggle,"data-target":"#bannerModal"},on:{"click":function($event){return _vm.bannerToggle(item)}}})])}),1),_c('div',{staticClass:"modal fade",staticStyle:{"z-index":"9999"},attrs:{"id":"bannerModal","data-backdrop":"false"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_vm._m(0),_c('detail',{attrs:{"cartTotal":_vm.cartTotal,"SKUObjectId":_vm.SKUObjectId},on:{"update-cartTotal":_vm.updateCartTotal}})],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close",attrs:{"data-dismiss":"modal"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('img',{attrs:{"width":"10%","src":"/img/close.png"}})])])
}]

export { render, staticRenderFns }