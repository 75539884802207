<template>
    <div>
        <CRow v-if="OrderType == 'delivery'">
            <CCol>
                <span class="toggle" v-c-emit-root-event:toggle-sidebar-mobile>
                    <i class="fi fi-rr-menu-burger"></i>
                </span>
            </CCol>
            <CCol style="text-align: end;">
                <router-link to="/delivery/mycart">
                    <span class="callStaff ">
                        <i class="fi fi-rr-shopping-cart"></i>
                    </span>
                </router-link>
            </CCol>
        </CRow>
        <div class="row" v-else>
            <div class="col-3">
                <span class="toggle" v-c-emit-root-event:toggle-sidebar-mobile>
                    <i class="fi fi-rr-menu-burger"></i>
                </span>
            </div>
            <div class="text-center pt-2 col-6">
                <div class="h4 text-dark table-name  font-weight-bold">
                    <span>
                        {{ tableName }}
                    </span>
                </div>
            </div>
            <div class="col-3" style="text-align: end;">
                <span class="callStaff" @click="() => { callStaffModal = true }">
                    <img src="https://cdn-icons-png.flaticon.com/512/3239/3239952.png" class="img-fluid pb-2"
                        style="width: 50%; filter: invert(100%);">
                </span>
            </div>
        </div>

        <div class="mt-4">
            <h3>{{ greetingMessage1 }}</h3>
            <p class="text-description">{{ greetingMessage2 }}</p>
        </div>
        <div class="w-100 text-center" v-if="showTimeoutBox">
            <div class="time-out-box">
                <div class="time-out-box-bg"></div>
                {{ $t('endTime') }} {{ orderEnd }}
            </div>
        </div>
        <!-- Call Staff Modal -->
        <call-staff v-bind:callStaffModal="callStaffModal" @update-status="callStaffModal = false"></call-staff>
    </div>
</template>

<script>
import poscrm from '@/services/poscrm'
import Detail from './AppProductDetails'
import CallStaff from '../views/dine-in/CallStaff'
import '@/util/menu.css'

export default {
    components: {
        Detail,
        CallStaff,
    },
    data() {
        return {
            greetingMessage1: '',
            greetingMessage2: '',
            callStaffModal: false,
            orderShopSettingCollection: {},
            orderEnd: null,
            showTimeoutBox: false, // New state to show/hide time-out box
        };
    },
    created() {
        this.getOrderShopSetting();
        let storedOrderEnd = localStorage.getItem('orderEnd'); // Fetch orderEnd instead of orderStart
        this.setUpSidebarOrderEnd(storedOrderEnd);
    },
    computed: {
        OrderType() {
            let type = window.location.pathname
            if (type === '/delivery/menulists') {
                return 'delivery'
            } else {
                return 'qr'
            }
        },
        tableName() {
            if (localStorage.getItem('table')) {
                let table = JSON.parse(localStorage.getItem('table')) || {}
                return table.name
            } else {
                return ''
            }
        },

    },
    methods: {
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    this.orderShopSettingCollection = res.data?.data?.[0] || null
                    this.greetingMessage1 = this.orderShopSettingCollection?.greetingMessage[0] || ''
                    this.greetingMessage2 = this.orderShopSettingCollection?.greetingMessage[1] || ''
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        setUpSidebarOrderEnd(storedOrderEnd) {
            if (storedOrderEnd) {
                let date = new Date(storedOrderEnd.trim()); // Ensure it's a valid string
                if (!isNaN(date.getTime())) { // Check if the date is valid
                    this.orderEnd = date.toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false // Use 24-hour format
                    });

                    // Check if orderEnd is within 30 minutes
                    this.checkOrderEndTime(date);
                } else {
                    this.orderEnd = null;
                    this.showTimeoutBox = false; // Hide box if invalid
                }
            } else {
                this.orderEnd = null;
                this.showTimeoutBox = false; // Hide box if no value
            }
        },
        checkOrderEndTime(orderEndDate) {
            let now = new Date();
            let timeDifference = orderEndDate - now; // Difference in milliseconds
            let minutesRemaining = timeDifference / (1000 * 60); // Convert to minutes

            // If orderEnd is within 30 minutes, show the box
            this.showTimeoutBox = minutesRemaining > 0 && minutesRemaining <= 15;
        },

    },
}
</script>
<style>
.table-name {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.time-out-box {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 1.5cap;
    overflow: hidden;
    position: relative;
    /* border: 2px solid var(--bg-color-theme); */
    color: var(--orange);
    font-size: large;
}

.time-out-box-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: var(--orange);
    top: 0;
    left: 0;
    opacity: 0.2;
}
</style>
